"use client"

import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Font from '@/fonts/font'
import Icon from '@/components/shared/Icon'
import { getSettings } from '@/lib/general/api'

const Error = async ({ statusCode }) => {
    const settings = await getSettings()
    return (
        <>
            <section
                className={`block px-6 xl:px-0 relative h-full-no-head-mobile md:h-full-no-head-desktop`}
                style={{ margin: 'auto' }}
            >
                <div
                    className="flex flex-wrap w-full top-1/2 left-0 absolute"
                    style={{ transform: 'translateY(-60%)' }}
                >
                    <div className="basis-full md:basis-1/2 flex justify-center md:justify-end md:pr-4 mb-6 md:mb-10">
                        <div>
                        <Icon
                            className="w-12 md:w-16"
                            style={{ transform: 'translateX(-100%)' }}
                            cdn={settings.cdn}
                            url={'/asset/images/cart/error-face.svg'}
                        />
                        </div>
                    </div>
                    <div
                        className={`${Font.Myriad} basis-full md:basis-1/2 md:pl-4 flex flex-col justify-center items-center md:items-start`}
                        style={{ color: '#437fb8' }}
                    >
                        <h1 className="text-4xl md:text-5xl font-bold mb-2">That’s an error.</h1>
                        <div className="flex justify-center mt-5">{statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}</div>
                        <div className="flex justify-center mt-5">
                            <Link
                                className={`font-bold text-lg rounded-full px-4 py-2 mb-5`}
                                href="/"
                                style={{ backgroundColor: '#ea7f7f', color: 'white' }}
                            >
                            Go back to Home
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

Error.getInitialProps = ({ res, error }) => {
    const statusCode = res ? res.statusCode : error ? error.statusCode : 404
    return { statusCode }
};

Error.propTypes = {
    statusCode: PropTypes.number,
};

export default Error;