'use client'

import { useEffect, useRef, useState } from 'react'
import 'external-svg-loader'

export default function Icon({ cdn, url, style, className }) {
  const [loaded, setLoaded] = useState(false)
  const [hasMounted, setHasMounted] = useState(false)
  const iconRef = useRef()

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (hasMounted) {
      iconRef.current.addEventListener('iconload', () => {
        setLoaded(true)
      })
    }
  }, [hasMounted])

  return (
    <>
      {hasMounted && (
        <svg
          data-src={`${cdn}${url}`}
          ref={iconRef}
          display={!loaded ? 'none' : 'inline'}
          suppressHydrationWarning={true}
          style={!style ? {} : { ...style }}
          className={className}
        />
      )}
    </>
  )
}
