import localFont from 'next/font/local'

const Myriad = localFont({ src: './Myriad Variable Concept.ttf' })
const Sergio = localFont({ src: './Sergio-Trendy-Regular.ttf' })

const Font = {
  Myriad: Myriad.className,
  Sergio: Sergio.className
}

export default Font
