export default function getLocale(data) {
  return new Proxy(
    {},
    {
      get(target, key) {
        if (typeof key !== 'string') return key
        const keys = key.split('/')
        let text = data
        for (const k of keys) {
          if (text[k]) {
            text = text[k]
          } else {
            text = key
            break
          }
        }
        return text
      },
      set() {
        return false
      }
    }
  )
}
