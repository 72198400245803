import Request from '@/lib/api/request'
import { cache } from 'react'
import locale from '@/lib/utilities/getLocale'

export const refreshTokenAPI = async () => {
  return await Request.post('auth/init-device').useAuth().send()
}

export const getMeta = cache(async () => {
  const response = await Request.post('layout/fetch-store-info')
    .body({
      thumbnail: {
        maxWidth: '800',
        type: 'webp'
      }
    })
    .useServer()
    .send()
  return response?.data.data
})

export const getLocale = cache(async () => {
  const response = await Request.post('layout/fetch-elements')
    .body({ elements: ['locale'] })
    .useServer()
    .send()
  const data = response?.data.data[0].detail
  const result = locale(data)
  return [result, data]
})

export const getMenu = cache(async () => {
  const response = await Request.post('layout/fetch-elements')
    .body({ elements: ['menu'] })
    .useServer()
    .send()
  return response?.data.data[0].detail
})

export const getSettings = cache(async () => {
  const response = await Request.post('layout/fetch-elements')
    .body({ elements: ['settings'] })
    .useServer()
    .send()
  return response?.data.data[0].detail
})

export const getLayout = cache(async () => {
  const response = await Request.post('layout/fetch-elements')
    .body({ elements: ['layout'] })
    .useServer()
    .send()
  return response?.data.data[0].detail
})

export const getStatement = cache(async () => {
  const response = await Request.post('layout/fetch-elements')
    .body({ elements: ['statement'] })
    .useServer()
    .send()
  return response?.data.data[0].detail
})
