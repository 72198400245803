import axios from 'axios'
import userStorage from '@/lib/api/userStorage'

export const axiosInstance = axios.create({
})

const CUSTOMIZED_USER_AGENT = 'FridayFitterAgent/1.0'

const refreshTokenLock = { request: null }
const createCartLock = { request: null }

async function refreshToken() {
  const storage = userStorage()
  if (refreshTokenLock.request) {
    await refreshTokenLock.request
  } else {
    refreshTokenLock.request = new Promise(async (resolve, reject) => {
      try {
        if (typeof window === 'undefined') {
          axiosInstance.defaults.headers['User-Agent'] = CUSTOMIZED_USER_AGENT
        }        
        const response = await axiosInstance.post(
          `/public-api/v1/auth/init-device`,
          {},
          {
            headers: { 'X-CLIENT-TOKEN': storage.clientToken },
            auth: false,
            skipAuthRefresh: true
          }
        )
        storage.clientToken = response.data.data.clientToken
        refreshTokenLock.request = null
        resolve()
      } catch (error) {
        throw error
      }
    })
    await refreshTokenLock.request
  }
}

async function createCart() {
  const storage = userStorage()
  if (createCartLock.request) {
    await createCartLock.request
  } else {
    createCartLock.request = new Promise(async (resolve, reject) => {
      try {
        if (typeof window === 'undefined') {
          axiosInstance.defaults.headers['User-Agent'] = CUSTOMIZED_USER_AGENT
        }        
        const response = await axiosInstance.post(
          `/api/v1/checkout/create-cart`,
          {},
          {
            headers: { 'X-CLIENT-TOKEN': storage.clientToken },
            auth: true,
            skipAuthRefresh: false,
            cart: false,
            skipCreateCart: true
          }
        )
        storage.cartId = response.data.data.id
        createCartLock.request = null
        resolve()
      } catch (error) {
        throw error
      }
    })
    await createCartLock.request
  }
}

axiosInstance.interceptors.request.use(
  async function (config) {
    if (config.auth && !config.headers['X-CLIENT-TOKEN']) {
      const storage = userStorage()
      try {
        await refreshToken()
      } catch (error) {
        throw error
      }
      config.headers['X-CLIENT-TOKEN'] = storage.clientToken
    }
    if (config.cart && !config.data?.cartId) {
      const storage = userStorage()
      try {
        await createCart()
      } catch (error) {
        throw error
      }
      config.data = { ...(config.data ?? {}), cartId: storage.cartId }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      !error.config.skipAuthRefresh
    ) {
      try {
        const storage = userStorage()
        await refreshToken()
        error.config.skipAuthRefresh = true
        error.config.headers['X-CLIENT-TOKEN'] = storage.clientToken
        return axiosInstance.request(error.config)
      } catch (err) {
        console.error(JSON.stringify(err, Object.getOwnPropertyNames(err), 2))
        //throw error
      }
    } else if (
      error.response &&
      error.config.cart &&
      !error.config.skipCreateCart
    ) {
      try {
        const storage = userStorage()
        await createCart()
        error.config.skipCreateCart = true
        error.config.data = {
          ...(error.config.data ?? {}),
          cartId: storage.cartId
        }
        return axiosInstance.request(error.config)
      } catch (err) {
        console.error(JSON.stringify(err, Object.getOwnPropertyNames(err), 2))
        //throw error
      }
    }
    return Promise.reject(error)
  }
)

export default class Request {
  static get = url => new Request(url)
  static post = url => new Request(url, 'POST')
  static patch = url => new Request(url, 'PATCH')
  static put = url => new Request(url, 'PUT')
  static del = url => new Request(url, 'DELETE')

  constructor(url, method = 'GET') {
    this._url = '/public-api/v1/' + url
    this._isAuth = false
    this._isCart = false
    this._method = method
    this._headers = {}
    this._headers['Access-Control-Allow-Origin'] = '*'
    this._urlParams = {}
    this._bodyData = undefined
    this._file = false
  }

  header(key, value) {
    if (value) this._headers[key] = value
    return this
  }

  urlParam(key, value) {
    if (value) this._urlParams[key] = value
    return this
  }

  urlParams(data) {
    if (data) Object.keys(data).forEach(key => this.urlParam(key, data[key]))
    return this
  }

  body(data) {
    if (data) this._bodyData = data
    return this
  }

  useAuth() {
    const storage = userStorage()
    if (!this._url.includes('init-device'))
      this._url = this._url.replace('public-api', 'api')
    if (storage.clientToken)
      this._headers['X-CLIENT-TOKEN'] = `${storage.clientToken}`
    this._isAuth = true
    return this
  }

  useCart() {
    this._isCart = true
    return this
  }

  useServer() {
    if (process.env.REACT_APP_DOMAIN)
      this._url = process.env.REACT_APP_DOMAIN + this._url
    else {
      this._url = process.env.__NEXT_PRIVATE_ORIGIN + this._url
    }
    return this
  }

  getFile() {
    this._file = true
    return this
  }

  async send() {
    const config = {
      url: this._url,
      method: this._method,
      headers: this._headers,
      params: this._urlParams,
      data: this._bodyData,
      auth: this._isAuth,
      cart: this._isCart,
      skipAuthRefresh: !this._isAuth,
      skipCreateCart: !this._isCart
    }
    if (this._file) {
      config.responseType = 'blob'
    }
    if (this._isCart) {
      const storage = userStorage()
      config.data = config.data ?? {}
      config.data.cartId = storage.cartId
    }
    try {
      if (typeof window === 'undefined') {
        axiosInstance.defaults.headers['User-Agent'] = CUSTOMIZED_USER_AGENT
      }
      const response = await axiosInstance.request(config)
      return response
    } catch (err) {
      console.error(JSON.stringify(err, Object.getOwnPropertyNames(err), 2))
      //throw err
    }
  }
}
