const getKey = key => window.btoa(key)

export default function userStorage() {
  return new Proxy(
    {},
    {
      get(_, key) {
        try {
          if(typeof localStorage==='undefined') return null
          const payload = localStorage.getItem(getKey(key))
          if (!payload) return null
          return payload
        } catch (error) {
          localStorage.removeItem(getKey(key))
          return null
        }
      },
      set(_, key, data) {
        try {
          if (data === null || data === undefined)
            localStorage.removeItem(getKey(key))
          else localStorage.setItem(getKey(key), data)
          return true
        } catch (error) {
          return false
        }
      }
    }
  )
}
